export default [

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/ticket/Ticket.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/tickets/mine',
    name: 'tickets-mine',
    component: () => import('@/views/ticket/TicketMine.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
  },
  {
    path: '/tickets/compose',
    name: 'tickets-compose',
    component: () => import('@/views/ticket/TicketComposeBig.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      // contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
  },
  {
    path: '/tickets/escalations',
    name: 'tickets-escalations',
    component: () => import('@/views/ticket/TicketEscalation.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
  },
  {
    path: '/tickets/label/:label',
    name: 'tickets-label',
    component: () => import('@/views/ticket/Ticket.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4, 5, 6].includes(Number(to.params.label))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/priority/:priority',
    name: 'tickets-priority',
    component: () => import('@/views/ticket/Ticket.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4].includes(Number(to.params.priority))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/channel/:channel',
    name: 'tickets-channel',
    component: () => import('@/views/ticket/Ticket.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4, 5, 6].includes(Number(to.params.channel))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/view/:id',
    name: 'tickets-view',
    component: () => import('@/views/ticket/TicketDetail.vue'),
    meta: {
      contentRenderer: 'sidebar-right',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
  },
  {
    path: '/tickets/escalate/:folder',
    name: 'tickets-escalate-folder',
    component: () => import('@/views/expert/TicketExpert.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if (['all', 'mine'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/escalate/view/:id',
    name: 'tickets-expert-view',
    component: () => import('@/views/expert/TicketExpertDetail.vue'),
    meta: {
      contentRenderer: 'sidebar-right',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
  },
  {
    path: '/tickets/escalate/status/:status',
    name: 'tickets-escalate-status',
    component: () => import('@/views/expert/TicketExpert.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2].includes(Number(to.params.status))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/escalate/label/:label',
    name: 'tickets-escalate-label',
    component: () => import('@/views/expert/TicketExpert.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4, 5, 6].includes(Number(to.params.label))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/escalate/priority/:priority',
    name: 'tickets-escalate-priority',
    component: () => import('@/views/expert/TicketExpert.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4].includes(Number(to.params.priority))) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/escalate/channel/:channel',
    name: 'tickets-escalate-channel',
    component: () => import('@/views/expert/TicketExpert.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'tickets',
    },
    beforeEnter(to, _, next) {
      if ([1, 2, 3, 4, 5, 6].includes(Number(to.params.channel))) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'User Manager',
      breadcrumb: [
        {
          text: 'User Manager',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- FAQ TAG REPLY -------------------------------*
  // *===============================================---*
  {
    path: '/author',
    name: 'author-list',
    component: () => import('@/views/author/Author.vue'),
    meta: {
      pageTitle: 'Customer Manager',
      breadcrumb: [
        {
          text: 'Customer Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/author/history/:id',
    name: 'author-history',
    component: () => import('@/views/author/AuthorHistory.vue'),
    meta: {
      pageTitle: 'Customer History',
      breadcrumb: [
        {
          text: 'Customer History',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (Number(to.params.id)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/author/company/:id',
    name: 'author-company',
    component: () => import('@/views/author/AuthorCompany.vue'),
    meta: {
      pageTitle: 'Customer Company History',
      breadcrumb: [
        {
          text: 'Customer Company History',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (to.params.id) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tag',
    name: 'tag-list',
    component: () => import('@/views/tag/Tag.vue'),
    meta: {
      pageTitle: 'Tag Manager',
      breadcrumb: [
        {
          text: 'Tag Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit',
    name: 'unit-list',
    component: () => import('@/views/unit/Unit.vue'),
    meta: {
      pageTitle: 'Unit Manager',
      breadcrumb: [
        {
          text: 'Unit Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tag/sub',
    name: 'tag-sub-list',
    component: () => import('@/views/tagsub/Sub.vue'),
    meta: {
      pageTitle: 'Sub Tag Manager',
      breadcrumb: [
        {
          text: 'Sub Tag Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faq',
    name: 'faq-list',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ Manager',
      breadcrumb: [
        {
          text: 'FAQ Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/knowledge/Faq.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      breadcrumb: [
        {
          text: 'Knowledge Base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pse-data',
    name: 'pse',
    component: () => import('@/views/pse/Pse.vue'),
    meta: {
      pageTitle: 'Search PSE Database',
      breadcrumb: [
        {
          text: 'Search PSE Database',
          active: true,
        },
      ],
    },
  },
  {
    path: '/knowledge/label/:label',
    name: 'knowledge-label',
    component: () => import('@/views/knowledge/Faq.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      breadcrumb: [
        {
          text: 'Knowledge Base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template',
    name: 'reply-list',
    component: () => import('@/views/answer/Answer.vue'),
    meta: {
      pageTitle: 'Response Template Manager',
      breadcrumb: [
        {
          text: 'Response Template Manager',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/form-pse/:key',
  //   name: 'form-pse',
  //   component: () => import('@/views/pages/others/FormPse.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  //   // beforeEnter(to, _, next) {
  //   //   if (to.params.key && to.query.no) next()
  //   //   else next({ name: 'error-404' })
  //   // },
  // },
  // {
  //   path: '/form-zoom',
  //   name: 'form-zoom',
  //   component: () => import('@/views/pages/others/FormZoom.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/hubungi-kami',
  //   name: 'hubungi-kami',
  //   component: () => import('@/views/pages/others/HubungiKami.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },

  // *===============================================---*
  // *--------- LOGIN & OTHERS -------------------------------*
  // *===============================================---*
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/user/userReport.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/expert-reports',
    name: 'expert-reports',
    component: () => import('@/views/expert/expertReport.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/user/userLogs.vue'),
    meta: {
      pageTitle: 'Activity Logs',
      breadcrumb: [
        {
          text: 'Activity Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sla-manager',
    name: 'sla-manager',
    component: () => import('@/views/pages/authentication/slaManager.vue'),
    meta: {
      pageTitle: 'SLA Manager',
      breadcrumb: [
        {
          text: 'SLA Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/pages/authentication/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-pass',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/password-reset/:key',
    name: 'auth-reset-pass',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
]

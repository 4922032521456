import Vue from 'vue'
// import Cookies from 'js-cookie'

// axios
import axios from 'axios'

const axiosInsMain = axios.create({
  baseURL: process.env.VUE_APP_API_MAIN_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
})

// // A function that calls '/api/csrf-cookie' to set the CSRF cookies. The
// // default is 'sanctum/csrf-cookie' but you can configure it to be anything.
// const setCSRFToken = () => axiosInsMain.get('/csrf-cookie') // resolves to '/api/csrf-cookie'.

// // Request interceptor. Runs before your request reaches the server
// const onRequest = config => {
//   // If http method is `post | put | delete` and XSRF-TOKEN cookie is
//   // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
//   // proceed with the initial response
//   if ((
//     config.method === 'post'
//           || config.method === 'put'
//           || config.method === 'delete'
//   )
//       && !Cookies.get('XSRF-TOKEN')) {
//     return setCSRFToken()
//       .then(() => config)
//   }
//   return config
// }

// // attach your interceptor
// axiosInsMain.interceptors.request.use(onRequest, null)

Vue.prototype.$http = axiosInsMain

export default axiosInsMain
